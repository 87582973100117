<template>
  <div class="page page--create-new-password animated_content">
    <SeqEntrance animation="animate__fadeInUp" tag="div">
      <StickyHeader class="animate__animated"/>
      <div class="box-container animate__animated">
        <div class="box-content">
          <SeqEntrance animation="animate__fadeInUp" tag="div">
            <div class="animate__animated">
              <p>Ustaw nowe hasło</p>
              <p><small>Hasło musi składać się z minimum 6 znaków</small></p>
            </div>
            <div class="animate__animated">
              <TextField
                  v-model="form"
                  @input="validateForm"
                  @keypress.enter="setPassword"
                  name="password"
                  label="Hasło"
                  placeholder="Hasło"
                  inputType="password"
                  required
              />
            </div>
            <div class="animate__animated">
              <TextField
                  v-model="form"
                  @input="validateForm"
                  @keypress.enter="setPassword"
                  name="password_confirm"
                  label="Powtórz hasło"
                  placeholder="Powtórz hasło"
                  inputType="password"
                  required
              />
            </div>
            <div class="animate__animated">
              <Btn @click="setPassword" :disabled="!formIsValid">Ustaw nowe hasło</Btn>
            </div>
            <div class="animate__animated">
              <p style="text-align: left; font-size: 13px;"><span class="required-star">*</span> Pole wymagane</p>
            </div>
          </SeqEntrance>
        </div>
      </div>
      <div class="flex-fill animate__animated"></div>
      <PlainFooter class="animate__animated"/>
    </SeqEntrance>
  </div>
</template>

<script>
import SeqEntrance from "@/components/SeqEntrance";
import Logo from "@/components/Logo";
import Btn from "@/components/Btn";
import StickyHeader from "@/parts/StickyHeader";
import PlainFooter from "@/parts/PlainFooter";
import TextField from "@/components/TextField";
import CheckboxField from "@/components/CheckboxField";

export default {
  name: "CreateNewPassword",
  components: {SeqEntrance,CheckboxField, TextField, PlainFooter, StickyHeader, Logo, Btn},
  props: {
    token: String,
  },
  data() {
    return {
      form: {
        password: '',
        password_confirm: '',
      },
      formIsValid: false,
    };
  },
  methods: {
    validateForm() {
      let ret = true;
      if (!this.form.password) {
        ret = false;
      } else if (this.form.password.length < 6) {
        ret = false;
      }
      if (!this.form.password_confirm) {
        ret = false;
      } else if (this.form.password_confirm.length < 6) {
        ret = false;
      }
      if (this.form.password !== this.form.password_confirm) {
        ret = false;
      }
      this.formIsValid = ret;
      return ret;
    },
    setPassword() {
      if (!this.validateForm()) {
        return;
      }
      if (this.form.password !== this.form.password_confirm) {
        this.$toast.error(`Podane hasła nie są zgodne`);
      }
      const _form = { ...this.form, token: this.token };
      this.$store.dispatch('setNewPassword', { form: _form }).then(({ statusCode, message }) => {
        if (statusCode === 200) {
          this.$toast.success(`Hasło zostało zmienione, możesz się teraz zalogować.`);
          this.$router.push({ name: 'Login' });
        } else {
          if (message === 'invalid_token') {
            this.$toast.error(`Ten link do zmiany hasła wygasł, spróbuj ponownie`);
          } else {
            this.$toast.error(`<strong>Upsss</strong><br>${message}`);
          }
          // if (message === 'password_mismatch') {
          //   this.$toast.error(`Podane hasło jest nieprawidłowe`);
          // } else {

          // }
        }
      });
    },
  },
}
</script>

<style scoped lang="scss">
.box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  .box-content {
    padding: 0 15px 15px 15px;
    max-width: 600px;
    width: 100%;
    border-radius: var(--border-radius);
    border-width: 1px;
    border-style: solid;
    border-color: var(--border);
  }
}
.flex-fill {
  flex: 1;
}
</style>
