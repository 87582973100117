<template>
  <div class="page page--login animated_content">
    <SeqEntrance animation="animate__fadeInUp" tag="div">
      <StickyHeader class="animate__animated"/>
      <div class="box-container animate__animated">
        <div class="box-content">
          <SeqEntrance animation="animate__fadeInUp" tag="div">
            <div class="animate__animated">
              <p>Przypomnij hasło</p>
              <p><small>
                Na podany poniżej e-mail wyślemy instrukcje zmiany hasła.
              </small></p>
            </div>
            <div class="animate__animated">
              <TextField
                  v-model="form"
                  @input="validateForm"
                  @keypress.enter="send"
                  name="email"
                  placeholder="Adres e-mail"
                  required
              />
            </div>
            <div class="animate__animated">
              <Btn @click="send" :disabled="!formIsValid">Wyślij</Btn>
            </div>
            <div class="animate__animated">
              <p style="text-align: left; font-size: 13px;"><span class="required-star">*</span> Pole wymagane</p>
            </div>
          </SeqEntrance>
        </div>
      </div>
      <div class="flex-fill animate__animated"></div>
      <PlainFooter class="animate__animated"/>
    </SeqEntrance>
  </div>
</template>

<script>
import SeqEntrance from "@/components/SeqEntrance";
import Logo from "@/components/Logo";
import Btn from "@/components/Btn";
import StickyHeader from "@/parts/StickyHeader";
import PlainFooter from "@/parts/PlainFooter";
import TextField from "@/components/TextField";
import CheckboxField from "@/components/CheckboxField";

export default {
  name: "PasswordRecovery",
  components: {SeqEntrance,CheckboxField, TextField, PlainFooter, StickyHeader, Logo, Btn},
  data() {
    return {
      form: {
        email: '',
      },
      formIsValid: false,
    };
  },
  methods: {
    validateForm() {
      let ret = true;
      if (!this.form.email) {
        ret = false;
      } else if (this.form.email.length < 3) {
        ret = false;
      }
      this.formIsValid = ret;
      return ret;
    },
    send() {
      if (!this.validateForm()) {
        return;
      }
      this.$store.dispatch('resetPassword', { form: this.form }).then(({ statusCode, message }) => {
        if (statusCode === 200) {
          this.$toast.success(`Instrukcja zmiany hasła została wysłana na podany e-mail`);
          this.$router.push({ name: 'Login' });
        } else {
          if (message === 'email_not_found') {
            this.$toast.error(`Nie znaleźliśmy konta z podanym adresem e-mail`);
          } else {
            this.$toast.error(`<strong>Upsss</strong><br>${message}`);
          }
          // if (message === 'password_mismatch') {
          //   this.$toast.error(`Podane hasło jest nieprawidłowe`);
          // } else {

          // }
        }
      });
    },
  },
}
</script>

<style scoped lang="scss">
.box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  .box-content {
    padding: 0 15px 15px 15px;
    max-width: 600px;
    width: 100%;
    border-radius: var(--border-radius);
    border-width: 1px;
    border-style: solid;
    border-color: var(--border);
  }
}
.flex-fill {
  flex: 1;
}
</style>
